/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}*/

/*.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}*/

/*.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
.header h1{
  font-family: 'palatino linotype', serif !important;
}

h2, h3, h4, .btn{
  font-family: proxima-n-w01-reg,sans-serif;
}

/*h1{
  font: normal normal normal 16px/1.4em 'palatino linotype',sans-serif;
}*/

h2, .h2{
  letter-spacing: 0.25em;
}

h3, .h3{
  /*color: #E3BB8A;*/
  color: white;
  /*font-family: 'proxima nova', serif;*/
  font-size: 16px !important;
  letter-spacing: 0.25em;
  line-height: 1.6em;
  font-weight: normal;
}

p, nav a, .p{
  font-family: 'palatino linotype',serif;
  font-size: 16px;
}

.header h1{
  color: white;
  font-size: 40px;
  letter-spacing: 0.25em;
  line-height: 1.6em;
}

.header p {
  font-size: 22px;
  font: normal normal normal 22px/1.4em 'palatino linotype',serif;
}

.header .btn-large{

}

nav{
  height: 72px;
  line-height: 72px;
  padding-top: 2%;
}

nav .container{
  width: 90% !important;
}

nav .brand-logo{
  position: relative !important;
  margin-top: 6px;
}

/*.brand-logo-desktop img{
  max-width: 80%;
}*/

nav .brand-logo-desktop{
  display: flex;
  justify-content: center;
}

nav ul{
  display: flex;
  justify-content: center;
  width: 100%;
}

nav a{
  color: #E3BB8A !important;
  /*font: normal normal normal 15px/1.4em 'palatino linotype',serif;*/
  font-family: 'palatino linotype',serif;
  padding: 0 10px !important;
  transition: color 0.4s ease 0s
}

nav svg{
  font-size: 1rem;
  /*vertical-align: text-bottom;*/
}

.brand{
  background-color: rgba(203, 161, 110, 1) !important;
}

.brand-light{
  color: rgba(203, 161, 110, 1);
}

.brand-dark{
  color: rgba(203, 161, 110, 1);
}

.brand-text--light{
  color: #E3BB8A !important;
}

.brand-border{
  border-top: 1px solid #E3BB8A;
  border-bottom: 1px solid #E3BB8A;
  border-left: .5px solid #E3BB8A;
  border-right: .5px solid #E3BB8A;
}

.brand-text--dark{
  color: #CBA16E;
}

.btn{
  font-size: 16px !important;
  border: 2px solid rgba(203, 161, 110, 1) !important;
  border-radius: 0 !important;
   height: 40px !important;
    line-height: 38px !important;
}

.uppercase{
  text-transform: uppercase !important; 
}

.p0{
  padding: 0 !important;
}

.m0{
  margin: 0 !important;
}

.mt0{
  margin-top: 0 !important;
}

.mb0{
  margin-bottom: 0 !important;
}

.hide{
  display: none;
}

section.header{
  padding-top: 25vh;
  height: 115vh;
}

/*====================================ABOUT====================================*/
section.about{
  margin-bottom: 40px;
}

.about .card{
  margin-top: -15vh;
}

.about .card .card-content{
  padding: 14vh 24px;
}

.parallax-section{
  /*margin-bottom: 40px !important;*/
}

.parallax { 
  /* The image used */
  background-image: url("law.jpeg");

  /* Set a specific height */
  height: 500px; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax-container {
  height: 400px;
}

.parallax-container img {
  height: 400px;
}

.parallax{
 /*   width: 100%;
    background-attachment: fixed;
    position: absolute;
    z-index: -1;*/
   /* background-image: url(../../law.jpeg);*/
}

@media only screen and (max-device-width: 982px) {
  .parallax {
    background-attachment: scroll;
  }
}

/*====================================SERVICES====================================*/
section.service-section{
  padding: 6% 0 !important;
}
div.row.services{
  margin-bottom: 45px !important;
}
.slip.card, .hospital.card, .injuries.card, .liability.card, .mva.card, .medication.card {
  background-position: center;
  background-size: cover;
  min-height: 250px;
  background-repeat: no-repeat;
}

.slip.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(injuries.jpg);
}

.hospital.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(mva.jpg);
}

.injuries.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(liability.jpg);
}

.mva.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(hammer.jpg);
}

.liability.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(handshake.jpg);
}

.medication.card{
  background-image: linear-gradient(rgba(0, 0, 0, .4),rgba(0, 0, 0, .4)),url(medication.jpg);
}

.services .card-content{
  position: relative;
  padding: 0 24px;
  height: 100%;
  min-height: 250px;
  margin: 0 auto;
}

.service-title{
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -25px;
  left: 5%;
  width: 90%;
  padding: 20px 0;
  border: 2px solid rgba(203, 161, 110, 1);
  background-color: rgba(39, 38, 36, 0.9);
  border-radius: 0;
}

.service-title p{
  color: #CBA16E;
  font-size: 20px;
  font-style: italic;
  /*text-transform: uppercase;*/
}

section.attorneys{
  padding-top: 2% !important;
}

.attorneys img{
  /*max-height: 190px;*/
  width: 250px;
  max-height: 170px;
}

footer div .brand-border{
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 200px;
}

footer i svg{
  vertical-align: text-bottom;
}

.footer-copyright{
  font-family: 'palatino linotype',serif;
  color: #E3BB8A !important;
  font-size: 12px;
  text-transform: uppercase;
}

@media screen and (min-width: 993px) {
  section.attorneys{
    padding-top: 6% !important;
    padding-bottom: 6% !important;
  }

  section.services, footer{
    padding-top: 6% !important;
  }

  footer{
    padding-top: 4% !important;
  }

  section.header{
    height: 115vh;
  }

  .container{
    width: 85% !important;
  }

  .header .btn{
    min-width: 282px;
  }
}

@media screen and (max-width: 992px) {
  .sidenav{
    width: 100vw !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-self: center;
    text-align: center;
  }
/*.sidenav-overlay{
  display: none !important;
}*/
  .sidenav li{
    width: 100%;

  }
}

@media screen and (max-width: 768px) {
  section.header{
    padding-top: 15vh;
    height: 102vh;
  }

  .about .card .card-content{
    padding: 4vh 24px;
  }

 /* .slip.card, .hospital.card, .injuries.card, .liability.card, .mva.card, .medication.card, .services .card-content {
    min-height: 200px !important;
  }*/

 /* .services p{
    font-size: 12px;
  }*/
}

@media screen and (min-width: 600px) and (max-width: 768px) {
  .slip.card, .hospital.card, .injuries.card, .liability.card, .mva.card, .medication.card, .services .card-content {
    min-height: 200px !important;
  }

  .services p{
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .button-collapse{
    margin-right: 16px;
  }

  .brand-logo img{
    width: 138px;
    height: 44px;
    display: flex;
    margin: 0 auto;
  }

  h1, .header h1{
    font-size: 38px !important;
  }

  .header h1{
    font-size: 30px !important;
    letter-spacing: 0.09em;
  }

  .header p{
    font-size: 20px;
  }

  .brand-border{
    border-top: .5px solid #E3BB8A;
    border-bottom: .5px solid #E3BB8A;
  }

  .btn, .btn-large{
    width: 100%;
    margin-bottom: 30px;
  }

  section.header{
    padding-top: 6vh;
    height: 102vh;
  }

  .about .card .card-content{
    padding: 4vh 24px;
  }

  div.row.services{
    margin-bottom: 0 !important;
  }

  .services .card{
    margin-bottom: 40px;
  }

  .attorneys img{
    margin-bottom: 25px;
  }

 /*  .services p{
    font-size: 16px;
  }*/
}

@media screen and (max-width: 320px) {
  .brand-logo img{
    width: 138px;
    height: 44px;
    display: flex;
    margin: 0 auto;
  }
  section.header {
    padding-top: 8vh;
    height: 102vh;
  }
  h1, .header h1{
    font-size: 24px !important;
    letter-spacing: 0;
    line-height: 1.2em;
  }
  .header p{
    font-size: 18px;
  }
  .header .btn{
    font-size: 14px !important;
    margin-bottom: 15px !important;
  }
}
